import * as Yup from "yup";
import { validarCNPJ, validate } from "~/hooks";
import { useSearch } from "../../../context/SearchProvider";

export const useValidator = () => {
  const { t } = useSearch();

  const schema = Yup.object().shape({
    document: Yup.string()
      .when("personType", {
        is: (personType) => personType === 1,
        then: Yup.string().test("test-cpf", t("alerts.cpf"), (value) =>
          validate(value),
        ),
        otherwise: Yup.string().notRequired(),
      })
      .when("personType", {
        is: (personType) => personType === 2,
        then: Yup.string().test("test-cnpj", t("alerts.cnpj"), (value) =>
          validarCNPJ(value),
        ),
        otherwise: Yup.string().notRequired(),
      }),
    jurisdiction: Yup.string().when("personType", {
      is: (personType) => personType === 3 || personType === 4,
      then: Yup.string()
        .required(t("evaluation.validations.jurisdiction"))
        .nullable(),
      otherwise: Yup.string().notRequired(),
    }),
    name_jurisdiction: Yup.string().when("personType", {
      is: (personType) => personType === 3 || personType === 4,
      then: Yup.string()
        .when({
          is: (value) => /[0-9]/.test(value),
          then: Yup.string().matches(/[a-zA-Z]/, t("alerts.name_jurisdiction"))
        })
        .when({
          is: (value) => /[^a-zA-Z0-9]/.test(value),
          then: Yup.string().matches(/[a-zA-Z]/, t("alerts.name_jurisdiction"))
        })
        .nullable(),
      otherwise: Yup.string().notRequired().nullable(),
    }),
    workflows: Yup.number().required(t("evaluation.validations.required")),
    risks: Yup.array(
      Yup.lazy((risk) => {
        if (Number(risk.mandatory)) {
          return Yup.object().shape({
            value: Yup.string().required(t("evaluation.validations.required")),
          });
        }
        return Yup.object().shape({
          value: Yup.string(),
        });
      }),
    ),
  });

  return {
    schema,
  };
};
